// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/components/Feedback.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
var _s = $RefreshSig$();
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/components/Feedback.tsx");
  import.meta.hot.lastModified = "1732006320680.989";
}
// REMIX HMR END

import { conform, useForm } from "@conform-to/react";
import { parse } from "@conform-to/zod";
import { InformationCircleIcon } from "@heroicons/react/20/solid";
import { EnvelopeIcon } from "@heroicons/react/24/solid";
import { Form, useActionData, useLocation, useNavigation } from "@remix-run/react";
import { useEffect, useState } from "react";
import { feedbackTypeLabel, schema } from "~/routes/resources.feedback";
import { Button } from "./primitives/Buttons";
import { Dialog, DialogContent, DialogHeader, DialogTrigger } from "./primitives/Dialog";
import { Fieldset } from "./primitives/Fieldset";
import { FormButtons } from "./primitives/FormButtons";
import { FormError } from "./primitives/FormError";
import { Icon } from "./primitives/Icon";
import { InfoPanel } from "./primitives/InfoPanel";
import { InputGroup } from "./primitives/InputGroup";
import { Label } from "./primitives/Label";
import { Paragraph } from "./primitives/Paragraph";
import { Select, SelectItem } from "./primitives/Select";
import { TextArea } from "./primitives/TextArea";
import { TextLink } from "./primitives/TextLink";
import { DialogClose } from "@radix-ui/react-dialog";
export function Feedback({
  button,
  defaultValue = "bug"
}) {
  _s();
  const [open, setOpen] = useState(false);
  const location = useLocation();
  const lastSubmission = useActionData();
  const navigation = useNavigation();
  const [type, setType] = useState(defaultValue);
  const [form, {
    path,
    feedbackType,
    message
  }] = useForm({
    id: "accept-invite",
    lastSubmission: lastSubmission,
    onValidate({
      formData
    }) {
      return parse(formData, {
        schema
      });
    },
    shouldRevalidate: "onInput"
  });
  useEffect(() => {
    if (navigation.formAction === "/resources/feedback" && navigation.state === "loading" && form.error === undefined && form.errors.length === 0) {
      setOpen(false);
    }
  }, [navigation, form]);
  return <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild>{button}</DialogTrigger>
      <DialogContent>
        <DialogHeader>Contact us</DialogHeader>
        <div className="mt-2 flex flex-col gap-4">
          <div className="flex items-center gap-4">
            <Icon icon={EnvelopeIcon} className="size-10 min-w-[2.5rem] text-blue-500" />
            <Paragraph variant="base/bright">
              How can we help? We read every message and will respond as quickly as we can.
            </Paragraph>
          </div>
          <hr className="border-charcoal-800" />
          <Form method="post" action="/resources/feedback" {...form.props} className="w-full">
            <Fieldset className="max-w-full gap-y-3">
              <input value={location.pathname} {...conform.input(path, {
              type: "hidden"
            })} />
              <InputGroup className="max-w-full">
                {type === "feature" && <InfoPanel icon={InformationCircleIcon} iconClassName="text-blue-500" panelClassName="w-full mb-2">
                    <Paragraph variant="small">
                      All our feature requests are public and voted on by the community. The best
                      way to submit your feature request is to{" "}
                      <TextLink to="https://feedback.trigger.dev">
                        post it to our feedback forum
                      </TextLink>
                      .
                    </Paragraph>
                  </InfoPanel>}
                {type === "help" && <InfoPanel icon={InformationCircleIcon} iconClassName="text-blue-500" panelClassName="w-full mb-2">
                    <Paragraph variant="small">
                      The quickest way to get answers from the Trigger.dev team and community is to{" "}
                      <TextLink to="https://trigger.dev/discord">ask in our Discord</TextLink>.
                    </Paragraph>
                  </InfoPanel>}
                <Select {...conform.select(feedbackType)} variant="tertiary/medium" value={type} defaultValue={type} setValue={v => setType(v)} placeholder="Select type" text={value => feedbackTypeLabel[value]} dropdownIcon>
                  {Object.entries(feedbackTypeLabel).map(([name, title]) => <SelectItem key={name} value={name}>
                      {title}
                    </SelectItem>)}
                </Select>
                <FormError id={feedbackType.errorId}>{feedbackType.error}</FormError>
              </InputGroup>
              <InputGroup className="max-w-full">
                <Label>Message</Label>
                <TextArea {...conform.textarea(message)} />
                <FormError id={message.errorId}>{message.error}</FormError>
              </InputGroup>
              <FormError>{form.error}</FormError>
              <FormButtons confirmButton={<Button type="submit" variant="primary/medium">
                    Send message
                  </Button>} cancelButton={<DialogClose asChild>
                    <Button variant="tertiary/medium">Cancel</Button>
                  </DialogClose>} />
            </Fieldset>
          </Form>
        </div>
      </DialogContent>
    </Dialog>;
}
_s(Feedback, "LKly8JAC4VlQ5vQqoAVS/bUTfsI=", false, function () {
  return [useLocation, useActionData, useNavigation, useForm];
});
_c = Feedback;
var _c;
$RefreshReg$(_c, "Feedback");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;